/* src/index.css */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background-color var(--transition-speed) ease, color var(--transition-speed) ease;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Global Box Sizing */
*, *::before, *::after {
  box-sizing: border-box;
}

/* CSS Variables */
:root {
  /* Spacing */
  --spacing-small: 8px;
  --spacing-medium: 16px;
  --spacing-large: 24px;
  --spacing-xlarge: 32px;

  /* Typography */
  --font-weight-header: 600;
  --font-size-header: 16px;
  --font-size-label: 16px;
  --font-size-message: 14px;
  --font-size-input: 16px;
  --font-size-button: 14px;

  /* Other */
  --transition-speed: 0.5s;
  --box-shadow-light: 0 2px 6px rgba(0, 0, 0, 0.08);
  --box-shadow-deep: 0 4px 12px rgba(0, 0, 0, 0.1);

  /* Consolidated Theme Colors */

  /* Light Theme */
  --light-color-background: #ffffff;
  --light-color-text: #000000;
  --light-color-header-bg: #ffffff;
  --light-color-header-text: #1f2937;
  --light-color-header-hover-bg: #f3f4f6;
  --light-color-header-hover-text: #2563eb;
  --light-color-border: #ddd;
  --light-color-sort-icon: #9ca3af;
  --light-color-sort-icon-active: #2563eb;
  --light-color-row-hover: #f9fafb;
  --light-color-sidebar-bg: #f9fafb;
  --light-color-message-user-bg: #2563eb;
  --light-color-message-assistant-bg: #e5e7eb;
  --light-color-message-text: #ffffff;
  --light-color-message-assistant-text: #1f2937;
  --light-color-button-bg: #2563eb;
  --light-color-button-text: #ffffff;
  --light-color-button-hover-bg: #1d4ed8;
  --light-color-input-bg: #f8f8f8;
  --light-color-input-border: #ccc;
  --light-color-icon: #ffc107; /* For sun icon */
  --light-color-icon-secondary: #4A4A4A; /* For other icons */
  --light-color-overall-background: rgb(239,239,248);

  /* Dark Theme */
  --dark-color-background: rgb(19, 18, 18); /* Component Background */
  --dark-color-text: rgb(234, 236, 246); /* Active Text */
  --dark-color-header-bg: rgb(48, 47, 47); /* Chatbox background */
  --dark-color-header-text: rgb(180, 180, 180); /* Header Text */
  --dark-color-border: #444; /* Light gray borders */
  --dark-color-hover-bg: rgb(28, 30, 36); /* Sidebar Tab Hover Background */
  --dark-color-sidebar-bg: rgb(24, 23, 23); /* Sidebar Background */
  --dark-color-sidebar-hover-bg: rgb(33, 33, 33); /* Sidebar Tab Hover Background */
  --dark-color-profile-dropdown-bg: rgb(47, 47, 47); /* Profile Dropdown Container */
  --dark-color-chatbox-bg: rgb(48, 47, 47); /* Chatbox background */
  --dark-color-note-bg: rgb(33, 33, 33); /* Note background */
  --dark-color-arrow-textbox: rgb(103, 103, 103); /* Arrow in textbox */
  --dark-color-icon: #ffffff; /* Icons in dark mode */
  --dark-color-icon-secondary: #ffffff; /* Added for consistency */
  --dark-color-sort-icon: #9ca3af;
  --dark-color-sort-icon-active: #2563eb;
  --dark-color-row-hover: var(--dark-color-hover-bg);
  --dark-color-button-bg: #444; /* Example addition */
  --dark-color-button-text: #ffffff; /* Example addition */
  --dark-color-button-hover-bg: #1d4ed8;
  --dark-color-input-bg: #333; /* Define if needed */
  --dark-color-input-border: #555; /* Define if needed */
  --dark-color-overall-background: rgb(19, 18, 18); /* Define if needed */

  /* AI Theme */
  --ai-color-background: rgb(239,239,248);
  --ai-color-text: #ffffff;
  --ai-color-border: #444;
  --ai-color-hover-bg: #444;
  --ai-color-icon: #ffffff;
  --ai-color-gradient: linear-gradient(90deg, #6a11cb, #2575fc);
  --ai-color-sort-icon: #9ca3af; /* Define if needed */
  --ai-color-sort-icon-active: #2563eb; /* Define if needed */
  --ai-color-row-hover: #444444; /* Define if needed */
  --ai-color-sidebar-bg: #f0f0f0; /* Define if needed */
  --ai-color-message-user-bg: #6a11cb; /* Define if needed */
  --ai-color-message-assistant-bg: #2575fc; /* Define if needed */
  --ai-color-message-text: #ffffff; /* Define if needed */
  --ai-color-message-assistant-text: #ffffff; /* Define if needed */
  --ai-color-button-bg: #6a11cb; /* Define if needed */
  --ai-color-button-text: #ffffff; /* Define if needed */
  --ai-color-button-hover-bg: #5f0a87;
  --ai-color-input-bg: #e0e0e0; /* Define if needed */
  --ai-color-input-border: #ccc; /* Define if needed */
  --ai-color-icon-secondary: #ffffff; /* Define if needed */
  --ai-color-overall-background: rgb(239,239,248); /* Define if needed */
  --ai-color-sort-icon: #9ca3af; /* Define if needed */
  --ai-color-sort-icon-active: #2563eb; /* Define if needed */

  /* Interactive States */
  /* Light Theme Interactive States */
  --light-color-button-hover-bg: #1d4ed8;
  --light-color-row-hover: #f9fafb;
  --light-color-sort-icon-active: #2563eb;

  /* Dark Theme Interactive States */
  --dark-color-button-hover-bg: #1d4ed8; /* You can adjust based on design */
  --dark-color-row-hover: #444444;
  --dark-color-sort-icon-active: #2563eb; /* Adjust as needed */

  /* AI Theme Interactive States */
  --ai-color-button-hover-bg: #1d4ed8;
  --ai-color-row-hover: #444444;
  --ai-color-sort-icon-active: #2563eb;
}

/* Updated class selectors to use generic variables */

.chatbox {
  background-color: var(--chatbox-background);
  color: var(--text);
  /* ... other styles ... */
}

.note {
  background-color: var(--note-background);
  /* ... other styles ... */
}

.textbox-arrow {
  color: var(--textbox-arrow);
  /* ... other styles ... */
}

.header-text {
  color: var(--header-text);
  /* ... other styles ... */
}

.sidebar {
  background-color: var(--sidebar-background);
  /* ... other styles ... */
}

.sidebar-tab:hover {
  background-color: var(--sidebar-hover-bg);
  /* ... other styles ... */
}

.profile-dropdown {
  background-color: var(--profile-dropdown-bg);
  /* ... other styles ... */
}

.container-button {
  background-color: var(--button-bg);
  color: var(--button-text);
  /* ... other styles ... */
}

.header-container, .sidebar, .chatbox, .note, .profile-dropdown {
  transition: background-color var(--transition-speed) ease, color var(--transition-speed) ease;
}
